export default class AddressAutocomplete {

    constructor(map, id, placeholder, noResultsMessage, apiKey) {
        this.map = map
        this.id = id;
        this.placeholder = placeholder;
        this.noResultsMessage = noResultsMessage;
        this.apiKey = apiKey;
        this.selector = `#map-search-address-${this.id}`
        this.geocoderApiUrl = "https://maps.googleapis.com/maps/api/geocode/json"
        this.init()
    }

    getCurrentLocalisation(){
        navigator.geolocation.getCurrentPosition((position) => {
            this.currnetLocalisation = position.coords
        }, (error) => {
            console.log(error)
        });
    }

    async getSearchCoordinates(){
        if(this.selected)
            return this.selected.location

        const searchedLocalizations = await this.getSearchedLocalisations(this.element.value)
        if(searchedLocalizations && searchedLocalizations.length != 0)
            return searchedLocalizations[0].location

        this.getCurrentLocalisation()
        return this.currnetLocalisation
    }

    init(){
        this.element = document.querySelector(this.selector)
        if(!this.element) return

        this.select = document.querySelector( `#map-search-address-radius-${this.id}`)
        if(!this.select) return

        this.getCurrentLocalisation()

        this.autocomplete = new autoComplete({
            selector: this.selector,
            threshold: 2,
            debounce: 300,
            submit: true,
            placeHolder: this.placeholder,
            searchEngine: (query, record) => {
                return record;
            },
            data: {
                src: async (query) => {
                    try {
                        this.selected = null
                        return await this.getSearchedLocalisations(query)

                    } catch (error) {
                        return error;
                    }
                },
                keys: ["name"]
            },
            resultsList: {
                element: (list, data) => {
                    if (!data.results.length) {
                        const message = document.createElement("div");
                        message.setAttribute("class", "no_result");
                        message.innerHTML = `<span>`+ this.noResultsMessage +`</span>`;
                        list.prepend(message);
                    }
                },
                noResults: true,
                maxResults: 50
            },
            events: {
                input: {
                    selection: (event) => {
                        this.element.value = event.detail.selection.value.name
                        this.selected = event.detail.selection.value
                        this.map.processFiltering()
                    }
                }
            }
        });

        this.element.addEventListener('input', () => {
            this.selected = null
        })

        this.select.addEventListener('change', () => {
            this.map.processFiltering()
        })
    }


    async getSearchedLocalisations(query){

        const url = this.getUrl(query)
        if(!url) return
        const source = await fetch(this.getUrl(query));
        const data = await source.json();

        if(data.results.length == 0) return []

        return data.results.map(result => {
            return {
                location: {
                    latitude: result.geometry.location.lat,
                    longitude: result.geometry.location.lng
                },
                name: result['formatted_address']
            }
        })
    }

    getUrl(query){
        if(!query) return
        const params = {
            key: this.apiKey,
            address: query
        }

        return this.geocoderApiUrl + '?' + ( new URLSearchParams( params ) ).toString();
    }

}
