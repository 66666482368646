export default class LocalisationAutocomplete {

    constructor(map, id, placeholder, noResultsMessage, localisations) {
        this.map = map
        this.id = id;
        this.placeholder = placeholder;
        this.noResultsMessage = noResultsMessage;
        this.localisations = localisations;
        this.selector = `#map-search-name-${this.id}`

        this.init()
    }

    init(){
        this.element = document.querySelector(this.selector)
        this.autocomplete = new autoComplete({
            selector: this.selector,
            threshold: 2,
            debounce: 300,
            submit: true,
            placeHolder: this.placeholder,
            searchEngine: 'strict',
            data: {
                src: this.localisations,
                keys: ["name"]
            },
            resultsList: {
                element: (list, data) => {
                    if (!data.results.length) {
                        const message = document.createElement("div");
                        message.setAttribute("class", "no_result");
                        message.innerHTML = `<span>`+ this.noResultsMessage +`</span>`;
                        list.prepend(message);
                    }
                },
                noResults: true,
                maxResults: 50
            },
            events: {
                input: {
                    selection: (event) => {
                        this.element.value = event.detail.selection.value.name
                        this.map.processFiltering()
                    }
                }
            }
        });
    }
}
