import LocalisationAutocomplete from './LocalisationAutocomplete'
import AddressAutocomplete from './AddressAutocomplete'

export class GoogleMaps {
    constructor(id, localisations, options = {}, moduleSettings = {}) {
        this.wrapper = document.querySelector(`.map-wrapper-${id}`)
        if (!this.wrapper) return
        this.id = id
        this.localisations = localisations
        this.options = options
        this.moduleSettings = moduleSettings
        this.map = null;
        this.markers = [];
        this.infoWindows = [];
        this.searchedLocalisations = this.localisations.filter(l => l)
        this.distances = {}

        this.init()
    }

    init() {
        this.mapOverlay = this.wrapper.querySelector('.map-overlay')
        this.createMap()
        this.addLocalisationsToMap()
        this.createLegendFiltering()
        this.setAutozoom()


        if (this.options.searchObjects)
            this.enableSearchByName()

        if (this.options.searchAddress)
            this.enableSearchByAddress()

        if (this.options.searchObjects || this.options.searchAddress)
            this.enableSearchButton()

        this.displayFilteredLocalizations()
    }

    createMap() {
        const center = this.getCenterCoordinates()

        this.map = new google.maps.Map(this.wrapper.querySelector(`#map-${this.id}`), {
            center: center,
            zoom: this.options.initialZoom,
            mapTypeId: this.moduleSettings.mapType,
            disableDefaultUI: true,
            zoomControl: true,
            styles: this.moduleSettings.styles
        });
    }

    getCenterCoordinates() {
        if (this.options.centerLocalisation) {
            return new google.maps.LatLng(this.options.centerLocalisation.latitude, this.options.centerLocalisation.longitude);
        }

        if (this.localisations.length > 0)
            return new google.maps.LatLng(this.localisations[0].latitude, this.localisations[0].longitude);

        return new google.maps.LatLng('50.06180475626318', '19.937458788356636')
    }

    addLocalisationsToMap() {
        this.localisations.forEach(localisation => {
            const latLng = new google.maps.LatLng(localisation.latitude, localisation.longitude);

            const marker = new google.maps.Marker({
                position: latLng,
                icon: `${localisation.marker}`,
                categoryIds: localisation.categoryIds
            });
            marker.set("id", localisation.id);
            this.markers.push(marker);

            const infoWindow = new google.maps.InfoWindow({
                content: `<div>
                    <div class="d-flex align-items-center justify-content-between mb-1">
                        <h4>${localisation.link ? `<a href="${localisation.link}">` : ''}${localisation.name}${localisation.link ? `</a>` : ''}</h4>
                    </div>
                    ${localisation.description}
                </div>`,
            });
            this.infoWindows.push(infoWindow);

            if (this.options.showSearchResults){
                const result = this.wrapper.querySelector(`.search-result[data-localisation-id="${localisation.id}"]`)
                const resultWrapper = this.getScrollParent(result)

                result.addEventListener("mouseenter", () => {
                    marker.setAnimation(google.maps.Animation.BOUNCE);
                });
                result.addEventListener("mouseleave", () => {
                    marker.setAnimation(null);
                });

                google.maps.event.addListener(marker, 'mouseover', () => {
                    result.classList.add('hovered');
                    if (resultWrapper) {
                        resultWrapper.scrollTo({
                            top: result.offsetTop - resultWrapper.offsetTop,
                            behavior: "smooth",
                        })
                    }
                });

                google.maps.event.addListener(marker, 'mouseout', () => {
                    result.classList.remove('hovered');
                });
            }

            marker.addListener("click", () => {
                infoWindow.open({
                    anchor: marker,
                    map: this.map,
                });
            });

            marker.setMap(this.map);
        });
    }

    getScrollParent(node) {
        if (node == null)
          return null;

        if (node.scrollHeight > node.clientHeight)
            return node;

        return this.getScrollParent(node.parentNode);
      }

    enableSearchByName() {
        this.nameAutocomplete = new LocalisationAutocomplete(this, this.id, this.options.namePlaceholder, this.options.noResultsMessage, this.localisations)
    }

    enableSearchByAddress() {
        this.addressAutocomplete = new AddressAutocomplete(this, this.id, this.options.addressPlaceholder, this.options.noResultsMessage, this.options.apiKey)
    }

    calculateDistance(l1, l2) {
        var R = 6371.0710; // Radius of the Earth in kilometers
        var rlat1 = l1.latitude * (Math.PI / 180); // Convert degrees to radians
        var rlat2 = l2.latitude * (Math.PI / 180); // Convert degrees to radians
        var difflat = rlat2 - rlat1; // Radian difference (latitudes)
        var difflon = (l2.longitude - l1.longitude) * (Math.PI / 180); // Radian difference (longitudes)

        var d = 2 * R * Math.asin(Math.sqrt(Math.sin(difflat / 2) * Math.sin(difflat / 2) + Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) * Math.sin(difflon / 2)));
        return d;
    }

    enableSearchButton() {
        const button = this.wrapper.querySelector(`#map-search-button-${this.id}`)
        button.addEventListener('click', () => {
            this.processFiltering()
        })
    }

    async processFiltering() {
        this.changeOverlayVisibility(true)

        await this.filterLocalisations()
        this.displayFilteredLocalizations()

        this.changeOverlayVisibility(false)
    }

    changeOverlayVisibility(show){
        if(!this.mapOverlay) return

        if(show)
            this.mapOverlay.classList.remove('d-none')
        else
            this.mapOverlay.classList.add('d-none')
    }

    async filterLocalisations() {
        this.searchedLocalisations = this.localisations.filter(l => l)

        if (this.options.searchObjects)
            this.filterByName()

        if (this.options.searchAddress)
            await this.filterByAddress()
    }

    filterByName() {
        if (!this.nameAutocomplete.element.value) return
        this.searchedLocalisations = this.searchedLocalisations.filter(l => l.name.includes(this.nameAutocomplete.element.value))
    }

    async filterByAddress() {
        this.distances = {}
        const searchLocalisation = await this.addressAutocomplete.getSearchCoordinates()

        if (!searchLocalisation) return this.removeSearchLocalisationMarker()

        this.addSearchLocalizationToMap(searchLocalisation)

        this.searchedLocalisations = this.searchedLocalisations.filter(l => {
            this.distances[l.id] = this.calculateDistance(l, searchLocalisation)
            return this.distances[l.id] < this.addressAutocomplete.select.value
        })
    }

    addSearchLocalizationToMap(localisation) {
        const latLng = new google.maps.LatLng(localisation.latitude, localisation.longitude);

        if(this.searchedLocalisationMarker){
            this.searchedLocalisationMarker.setPosition(latLng)
        }else{
            this.searchedLocalisationMarker = new google.maps.Marker({
                position: latLng
            });
            this.searchedLocalisationMarker.setMap(this.map);
        }
    }

    removeSearchLocalisationMarker(){
        if(!this.searchedLocalisationMarker) return
        this.searchedLocalisationMarker.setMap(null);
        this.searchedLocalisationMarker = null
    }

    displayFilteredLocalizations() {
        this.filterCategories()

        this.markers.forEach(marker => {
            const isDisplayed = this.shouldLocalisationBeDisplayedById(marker.id)
            marker.setVisible(isDisplayed);

            const infoWindows = this.infoWindows.filter(i => i.anchor == marker )
            if(infoWindows.length > 0 && !isDisplayed){
                infoWindows[0].close()
            }
        })
        this.setAutozoom()

        if (this.options.showSearchResults)
            this.displaySearchResultCards()
    }

    filterCategories() {
        this.localisationsToDisplay = this.searchedLocalisations.filter(localisation => {

            const selectedCategories = this.categoryCheckboxes.filter(check => check.checked).map(check => parseInt(check.value))
            const selectedCaregoriesFromGroups = this.categorySelect.filter(select => select.value != 'all').map(select => parseInt(select.value))

            let containsGroup = true

            if(selectedCaregoriesFromGroups.length > 0){
                containsGroup = selectedCaregoriesFromGroups.every(item => localisation.categoryIds.includes(item));
            }

            if(this.categoryCheckboxes.length > 0)
                return containsGroup && (localisation.categoryIds.some(item => selectedCategories.includes(item)) || selectedCategories.length == 0);
            return containsGroup
        })
    }

    displaySearchResultCards() {
        const results = this.wrapper.querySelectorAll('.search-result')

        results.forEach(r => {
            const id = r.dataset.localisationId
            const isDisplayed = this.shouldLocalisationBeDisplayedById(id)

            const badge = r.querySelector('.badge')
            if(this.distances[id]){
                badge.textContent = `${Math.round(this.distances[id] * 10) /10 } km`
                badge.classList.remove('d-none')
            }else {
                badge.classList.add('d-none')
            }

            if (isDisplayed)
                r.classList.remove('d-none')
            else
                r.classList.add('d-none')
        })

        const noResultsElement = this.wrapper.querySelector('.no-search-results')
        if (this.localisationsToDisplay.length == 0) {
            noResultsElement.classList.remove('d-none')
        } else {
            noResultsElement.classList.add('d-none')
        }
    }

    shouldLocalisationBeDisplayedById(id) {
        return this.localisationsToDisplay.filter(e => e.id == id).length > 0
    }

    createLegendFiltering() {
        this.categoryCheckboxes = [...this.wrapper.querySelectorAll(`.map-${this.id}-legend-checkbox`)]
        this.categoryCheckboxes.forEach(check => {
            check.addEventListener('change', (e) => {
                this.displayFilteredLocalizations()
            })
        })

        this.categorySelect = [...this.wrapper.querySelectorAll(`.map-${this.id}-legend-select`)]
        this.categorySelect.forEach(check => {
            check.addEventListener('change', (e) => {
                this.displayFilteredLocalizations()
            })
        })
    }

    setAutozoom() {
        if (this.options.autoZoom && this.markers.filter(m => m.visible).length > 0) {
            let bounds = new google.maps.LatLngBounds();

            this.markers.forEach(marker => {
                if (marker.visible)
                    bounds.extend(marker.getPosition());
            })
            this.map.fitBounds(bounds);

            if(this.markers.filter(m => m.visible).length == 1){
                this.map.setZoom(this.moduleSettings.initialZoom)
            }
        }
    }
}


window.Iceberg.GoogleMaps = GoogleMaps
